import { default as financingZjvSEohRmDMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as indexTkQmx8X0D7Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyQzVVcpJZqLMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamlcsOwwS6T3Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as copperas_45cove_45areaEVU7V2gaTYMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45area1wsW4KPNxMMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45areacqYu4LXdXVMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaiS9pXhuAwqMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45areaE0zMKlc1YwMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45areao4JG4jJ3FpMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45areaEGVnx3fN3YMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45areaWXrzJSypdeMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_933ekSk7fLYsMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as index3iDQ1CD320Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as index543lqSvQnSMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93O5ccsgDQNnMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexViyM2FdJTSMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as park_45meadowsmSHDGs3XBPMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue?macro=true";
import { default as rosenthal_45estatesev1SeC8w0LMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue?macro=true";
import { default as templateeSMA0zTs3kMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/coming-soon/template.vue?macro=true";
import { default as indexPv4ru5BGseMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45questionSJurVKSxGOMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesn0sa5O7Da1Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqou00AujM2JMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesqltoEtPyGZMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexR8CHytudCRMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsCN4GqUyD5vMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiryr3TOtVL8q2Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offersbKvxqnHu9dMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexyGKOfTpb3bMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexvdVzcZRAnqMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexi554o8SDHrMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexXN0Y1b1D5GMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as indexBGOcv4nFORMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionpqmsGgXsW9Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as _91testimonial_93A0WgX3wan1Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexvZgN8cofcRMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as index2abYaJtaQiMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as _91_46_46_46slug_93BrHQo37YoqMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as indexdZWTsyLB2gMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favoritesyRSU0qeYbwMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchDmCsgAJ5vkMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communities2Rk33Uu8PzMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as easyhouseUbJRxjvtccMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionAKVRWQ2LqjMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageKJrme1gupxMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexDZDUrJ1oRlMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsrNdeUrXv3vMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as indexfXmo2EEil1Meta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandboxxTNr905NUjMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preserve6j8xxcAnmIMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creekYvbndXIEaAMeta } from "/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: "about-us-financing",
    path: "/about-us/financing",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/financing.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-story",
    path: "/about-us/our-story",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/our-story.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-team",
    path: "/about-us/our-team",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: "areas-copperas-cove-area",
    path: "/areas/copperas-cove-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-georgetown-area",
    path: "/areas/georgetown-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-killeen-area",
    path: "/areas/killeen-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/killeen-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-lorena-area",
    path: "/areas/lorena-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/lorena-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-nolanville-area",
    path: "/areas/nolanville-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-salado-area",
    path: "/areas/salado-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/salado-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-temple-area",
    path: "/areas/temple-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/temple-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-troy-area",
    path: "/areas/troy-area",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/areas/troy-area.vue").then(m => m.default || m)
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93O5ccsgDQNnMeta || {},
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-park-meadows",
    path: "/coming-soon/park-meadows",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-rosenthal-estates",
    path: "/coming-soon/rosenthal-estates",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-template",
    path: "/coming-soon/template",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/coming-soon/template.vue").then(m => m.default || m)
  },
  {
    name: "communities-community",
    path: "/communities/:community()",
    meta: indexPv4ru5BGseMeta || {},
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/communities/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-ask-a-question",
    path: "/contact-us/ask-a-question",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-faq",
    path: "/contact-us/faq",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-homeowner-resources",
    path: "/contact-us/homeowner-resources",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-trade-partner-inquiry",
    path: "/contact-us/trade-partner-inquiry",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue").then(m => m.default || m)
  },
  {
    name: "current-offers",
    path: "/current-offers",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: "events-event",
    path: "/events/:event()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-location-community",
    path: "/homes/:location()/:community()",
    meta: indexi554o8SDHrMeta || {},
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-plans-plan",
    path: "/homes/plans/:plan()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "land-acquisition",
    path: "/land-acquisition",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-testimonial",
    path: "/lifestyle/reviews/:testimonial()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-category-category",
    path: "/lifestyle/reviews/category/:category()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews",
    path: "/lifestyle/reviews",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "marshallfire-slug",
    path: "/marshallfire/:slug(.*)*",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "models-model",
    path: "/models/:model()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: "new-home-search",
    path: "/new-home-search",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-communities",
    path: "/our-homes/communities",
    alias: ["/our-homes/model-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/communities.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-easyhouse",
    path: "/our-homes/home-collections/easyhouse",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-limitededition",
    path: "/our-homes/home-collections/limitededition",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-wee-cottage",
    path: "/our-homes/home-collections/wee-cottage",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: "privacyterms",
    path: "/privacyterms",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: "qmi-qmi",
    path: "/qmi/:qmi()",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "the-preserve",
    path: "/the-preserve",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/the-preserve.vue").then(m => m.default || m)
  },
  {
    name: "the-ridge-at-knob-creek",
    path: "/the-ridge-at-knob-creek",
    component: () => import("/codebuild/output/src1744763867/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue").then(m => m.default || m)
  }
]